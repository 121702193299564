import { LanguageSelectorService } from '@services/language-selector.service';
import { emptyString } from 'app/constants/global.constants';
import { TranslationType } from 'app/repository/models/need.model';
import { NullUndefinedType } from 'app/types/global.types';

import { matchLocaleLanguage } from './match-locale-language.function';

/**
 * translateTo function
 *
 * @returns {LanguageSelectorService} res
 */
export function translateTo<T>(): (
  row: (T & { names: TranslationType[] }) | NullUndefinedType,
  langService: LanguageSelectorService
) => string {
  return (row, langService) =>
    !row
      ? emptyString
      : row.names?.filter((e) => {
          if (!e.localeLanguage) {
            return false;
          }
          return matchLocaleLanguage(e.localeLanguage, langService.bSubject.value);
        })[0]?.name || emptyString;
}
