import { environment } from '@environment';
import { emptyString } from 'app/constants/global.constants';
// BASE PATH CONSTANTS
const CNV_WEB_ARTICLES = `web-articles/v1`;
const BACK_WEB_ARTICLES = `${environment.apiBaseCnaPath}/${CNV_WEB_ARTICLES}`;
const CNV_WEB_ASSORTMENT = `web-assortment/v1`;
const BACK_WEB_ASSORTMENT = `${environment.apiBaseCnaPath}/${CNV_WEB_ASSORTMENT}`;
const BACK_WEB_ARTICLES_EXT = `${environment.apiBaseCnaPathExt}/${CNV_WEB_ARTICLES}`;
const BACK_WEB_ASSORTMENT_EXT = `${environment.apiBaseCnaPathExt}/${CNV_WEB_ASSORTMENT}`;

export const ENDPOINTS = {
  imageProfile: `${BACK_WEB_ARTICLES}/home/employeePhoto`,
  needs: `${BACK_WEB_ARTICLES}/needs/`,
  createNeed: `${BACK_WEB_ARTICLES}/needs`,
  updateNeed: `${BACK_WEB_ARTICLES}/needs`,
  needsList: `${BACK_WEB_ARTICLES}/needs/list`,
  productsList: `${BACK_WEB_ARTICLES}/variableArticles/list`,
  variableArticle: (variableArticle: string) => `${BACK_WEB_ARTICLES}/variableArticles/${variableArticle}`,
  needCostCenters: (needId: string) => `${BACK_WEB_ASSORTMENT}/needs/${needId}/costCenters`,
  postVariableArticle: `${BACK_WEB_ARTICLES}/variableArticles`,
  deleteVariableArticles: (statusId: string) => `${BACK_WEB_ARTICLES}/variableArticles?statusId=${statusId}`,
  latestMovementsList: `${BACK_WEB_ARTICLES}/home/latestMovements`,
  provisioningLatestMovementsList: `${BACK_WEB_ARTICLES}/provisioning/latestMovements`,
  chartsHome: `${BACK_WEB_ARTICLES}/home/charts`,
  hierarchiesList: `${BACK_WEB_ARTICLES}/hierarchies/list`,
  countryList: `${BACK_WEB_ARTICLES}/common/countries`,
  chargeOfMaintenance: `${BACK_WEB_ARTICLES}/common/chargeOfMaintenance`,
  genericNameClassifications: `${BACK_WEB_ARTICLES}/common/genericNameClassifications`,
  characteristicList: (hierarchyNodeId: string) =>
    `${BACK_WEB_ARTICLES}/hierarchies/${hierarchyNodeId}/characteristics`,
  needProducts: (needId: string) => `${BACK_WEB_ARTICLES}/needs/${needId}/articles`,
  purchasingManagers: `${BACK_WEB_ARTICLES}/common/purchasingManagers`,
  articleTypes: `${BACK_WEB_ARTICLES}/common/articleType`,
  costCenterTypes: `${BACK_WEB_ASSORTMENT}/common/costCenterTypes`,
  businessCenterSubtypes: `${BACK_WEB_ASSORTMENT}/common/businessCenterSubtypes`,
  budgetImplementationTypes: `${BACK_WEB_ASSORTMENT}/common/budgetImplementationTypes`,
  budgetImplementationTypesNeed: (hierarchyNodeId: string) =>
    `${BACK_WEB_ARTICLES}/hierarchies/${hierarchyNodeId}/budgetImplementationTypes`,
  costCenters: `${BACK_WEB_ASSORTMENT}/common/costCenters`,
  costCenterAssortment: `${BACK_WEB_ASSORTMENT}/assortment/costCenterAssortment`,
  suppliers: (search: string, pageNumber?: number, pageSize?: number) =>
    `${BACK_WEB_ASSORTMENT}/common/suppliers?search=${search}${
      [null, undefined].every((e) => pageNumber !== e) ? '&pageNumber=' + pageNumber : emptyString
    }${[null, undefined].every((e) => pageSize !== e) ? '&pageSize=' + pageSize : emptyString}`,
  statuses: (statusType: number) => `${BACK_WEB_ARTICLES}/common/status?statusType=${statusType}`,
  codeGenerator: `${BACK_WEB_ARTICLES}/common/codeGenerator`,
  brands: `${BACK_WEB_ARTICLES}/common/brands`,
  models: (brandId: number) => `${BACK_WEB_ARTICLES}/common/brands/${brandId}/models`,
  scopeTypes: `${BACK_WEB_ASSORTMENT}/geographicalBoundaries/scopeTypes`,
  geographicalBoundaries: `${BACK_WEB_ASSORTMENT}/geographicalBoundaries`,
  geographyImplantations: (needId: string) => `${BACK_WEB_ASSORTMENT}/needs/${needId}/implantations`,
  generateArticles: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/articles`,
  relationships: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/relationships`,
  uploadImage: `${BACK_WEB_ARTICLES}/images/upload`,
  assignImageVariableArticle: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/assignImage`,
  assignImageArticle: `${BACK_WEB_ARTICLES}/articles/assignImage`,
  articles: (variableArticle: string) => `${BACK_WEB_ARTICLES}/variableArticles/${variableArticle}/articles`,
  deleteArticle: `${BACK_WEB_ARTICLES}/articles`,
  copyFormats: `${BACK_WEB_ARTICLES}/articleFormats/copy`,
  listArticleFormats: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/articleFormats/ungrouped`,
  listArticleFormatsGrouped: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/articleFormats`,
  listArticleBySupplierAssortment: (variableArticleId: string, supplierId: string) =>
    `${BACK_WEB_ASSORTMENT}/variableArticles/${variableArticleId}/suppliers/${supplierId}/articleFormats`,
  getVariableArticleSupplierAssortmentDetailedInfoList: (variableArticleId: string, supplierId: string) =>
    `${BACK_WEB_ASSORTMENT}/variableArticles/${variableArticleId}/suppliers/${supplierId}`,
  listFormats: (articleId: string) => `${BACK_WEB_ARTICLES}/articles/${articleId}/formats`,
  format: (articleId: string, handlingUnitId: string) =>
    `${BACK_WEB_ARTICLES}/articles/${articleId}/formats/${handlingUnitId}`,
  assignImageFormat: `${BACK_WEB_ARTICLES}/articleFormats/assignImage`,
  variableArticleSuppliers: (variableArticleId: string) =>
    `${BACK_WEB_ASSORTMENT}/variableArticles/${variableArticleId}/suppliers/list`,
  handlingUnits: `${BACK_WEB_ARTICLES}/common/handlingUnits`,
  supplierInfo: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/supplierInfo`,
  supplierAssortment: (variableArticleId: string, supplierId: string) =>
    `${BACK_WEB_ASSORTMENT}/supplierAssortment?variableArticleId=${variableArticleId}&supplierId=${supplierId}`,
  getSupplierAssortment: (articleId?: string, supplierId?: string, handlingUnitId?: string) =>
    `${BACK_WEB_ASSORTMENT}/supplierAssortment?articleId=${articleId ?? emptyString}&supplierId=${
      supplierId ?? emptyString
    }&handlingUnitId=${handlingUnitId ?? emptyString}`,
  updateSupplierAssortmentAsSupplier: (articleId?: string, supplierId?: string, handlingUnitId?: string) =>
    `${BACK_WEB_ASSORTMENT}/supplierAssortment/supplierInfo?articleId=${articleId ?? emptyString}&supplierId=${
      supplierId ?? emptyString
    }&handlingUnitId=${handlingUnitId ?? emptyString}`,
  unitOfMeasure: `${BACK_WEB_ARTICLES}/common/unitsOfMeasure`,
  supplierNegotiations: (variableArticleId: string) =>
    `${BACK_WEB_ASSORTMENT}/variableArticles/${variableArticleId}/supplierNegotiations`,
  supplierFormats: (variableArticleId: string, supplierId: string) =>
    `${BACK_WEB_ASSORTMENT}/variableArticles/${variableArticleId}/supplierNegotiations/${supplierId}/articleFormats`,
  supplierNegotiationsInfo: (variableArticleId: string, supplierId: string) =>
    `${BACK_WEB_ASSORTMENT}/variableArticles/${variableArticleId}/supplierNegotiations/${supplierId}/negotiations`,
  supplierNegotiationFormats: (variableArticleId: string, negotiationId: string, supplierId: string) =>
    `${BACK_WEB_ASSORTMENT}/variableArticles/${variableArticleId}/supplierNegotiations/${supplierId}/negotiations/${negotiationId}/articleFormats`,
  negotiation: (variableArticleId: string, supplierId: string, negotiationId: string) =>
    `${BACK_WEB_ASSORTMENT}/variableArticles/${variableArticleId}/supplierNegotiations/${supplierId}/negotiations/${negotiationId}`,
  geographyNegotiation: (needId: string) => `${BACK_WEB_ASSORTMENT}/needs/${needId}/implantations`,
  currency: `${BACK_WEB_ASSORTMENT}/common/currency`,
  amountObservations: (observationType: number) =>
    `${BACK_WEB_ASSORTMENT}/supplierAssortment/amountObservations?observationType=${observationType}`,
  certificationDatePriceTypes: `${BACK_WEB_ASSORTMENT}/supplierAssortment/certificationDatePriceTypes`,
  currencySupplier: (supplierId: string) => `${BACK_WEB_ASSORTMENT}/supplier/${supplierId}/currency`,
  timeUnits: `${BACK_WEB_ARTICLES}/common/timeUnits`,
  getSupplierDocument: (documentId: string | undefined, variableArticleId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/supplierDocument${
      documentId ? '?documentId=' + documentId : emptyString
    }`,
  postSupplierDocument: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/supplierDocument/upload`,
  getSupplierAssortmentDocument: (
    articleId?: string,
    supplierId?: string,
    handlingUnitId?: string,
    documentTypeId: '1' | '2' = '1'
  ) =>
    `${BACK_WEB_ARTICLES}/supplierAssortment/supplierDocument?articleId=${articleId}&handlingUnitId=${handlingUnitId}&documentTypeId=${documentTypeId}${
      supplierId ? '&supplierId=' + supplierId : emptyString
    }`,
  getNodeType: (hierarchyNodeId: string) => `${BACK_WEB_ARTICLES}/hierarchies/${hierarchyNodeId}/nodeType`,
  getSupplierDocumentList: (articleId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${articleId}/supplierDocument/list`,
  taxCharts: `${BACK_WEB_ARTICLES}/tax/charts`,
  taxVariableArticles: `${BACK_WEB_ARTICLES}/tax/variableArticles/list`,
  taxInfo: (variableArticleId: string) => `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/taxInfo`,
  fiscalClassifications: `${BACK_WEB_ARTICLES}/tax/fiscalClassifications`,
  taricCodes: (variableArticleId: string, search: string, pageNumber?: number, pageSize?: number) =>
    `${BACK_WEB_ARTICLES}/tax/taricCodes?variableArticleId=${variableArticleId}&search=${search}${
      [null, undefined].every((e) => pageNumber !== e) ? '&pageNumber=' + pageNumber : emptyString
    }${[null, undefined].every((e) => pageSize !== e) ? '&pageSize=' + pageSize : emptyString}`,
  tax: `${BACK_WEB_ARTICLES}/tax`,
  accountantCharts: `${BACK_WEB_ARTICLES}/accounting/charts`,
  accountantVariableArticles: `${BACK_WEB_ARTICLES}/accounting/variableArticles/list`,
  provisioningCharts: `${BACK_WEB_ARTICLES}/provisioning/charts`,
  provisioningVariableArticles: `${BACK_WEB_ARTICLES}/provisioning/variableArticles/list`,
  provisioningServiceModels: (needId: string) => `${BACK_WEB_ARTICLES}/provisioning/serviceModels?needId=${needId}`,
  provisioningServiceTypes: (serviceModelId?: string, financialCompanyId?: string) =>
    `${BACK_WEB_ARTICLES}/provisioning/serviceTypes?serviceModelId=${serviceModelId}&financialCompanyId=${financialCompanyId}`,
  getProvisioningInfo: (variableArticle: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticle}/provisioning`,
  downloadBulkSparePartsTemplate: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES /* _EXT */}/bulk/spareParts/downloadTemplate?variableArticleId=${variableArticleId}`,
  getUploadSparePartsURL: (mimeType: string, contentLength: number, variableArticleId: string) =>
    `${
      BACK_WEB_ARTICLES /* _EXT */
    }/bulk/spareParts/uploadFile?mimeType=${mimeType}&contentLength=${contentLength}&variableArticleId=${variableArticleId}`,
  postUploadSparePartsFile: `${BACK_WEB_ARTICLES /* _EXT */}/bulk/spareParts/uploadFile`,
  getBulkSpareParts: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES /* _EXT */}/bulk/spareParts?variableArticleId=${variableArticleId}`,
  deleteBulkSpareParts: (variableArticleId: string, supplierId: string) =>
    `${BACK_WEB_ARTICLES /* _EXT */}/bulk/spareParts?articleId=${variableArticleId}&supplierId=${supplierId}`,
  saveBulkSpareParts: `${BACK_WEB_ARTICLES /* _EXT */}/bulk/spareParts/save`,
  getUploadSupplierDocumentURL: (
    supplierId: string | undefined,
    documentTypeId: number,
    documentName: string,
    mimeType: string,
    contentLength: number,
    variableArticleId: string
  ) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/supplierDocument/upload?documentTypeId=${documentTypeId}&documentName=${documentName}&mimeType=${mimeType}&contentLength=${contentLength}${
      supplierId ? '&supplierId=' + supplierId : emptyString
    }`,
  accountingTypes: `${BACK_WEB_ARTICLES}/accounting/accountingTypes`,
  accountantInfo: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/accountingInfo`,
  expenseTypes: `${BACK_WEB_ARTICLES}/accounting/expenseTypes`,
  accountTypes: (variableArticleId: string, search: string, pageNumber?: number, pageSize?: number) =>
    `${BACK_WEB_ARTICLES}/accounting/accountTypes?variableArticleId=${variableArticleId}&search=${search}${
      [null, undefined].every((e) => pageNumber !== e) ? '&pageNumber=' + pageNumber : emptyString
    }${[null, undefined].every((e) => pageSize !== e) ? '&pageSize=' + pageSize : emptyString}`,
  fixedAssetTypes: (variableArticleId: string, search: string, pageNumber?: number, pageSize?: number) =>
    `${BACK_WEB_ARTICLES}/accounting/fixedAssetTypes?variableArticleId=${variableArticleId}&search=${search}${
      [null, undefined].every((e) => pageNumber !== e) ? '&pageNumber=' + pageNumber : emptyString
    }${[null, undefined].every((e) => pageSize !== e) ? '&pageSize=' + pageSize : emptyString}`,
  getUploadPlasticDocumentURL: (
    articleId: string,
    handlingUnitId: string,
    supplierId: string | undefined,
    documentName: string,
    mimeType: string,
    contentLength: number
  ) =>
    `${BACK_WEB_ASSORTMENT}/${
      supplierId ? emptyString : 'b2c/'
    }supplierAssortment/plasticDocument/upload?articleId=${articleId}&handlingUnitId=${handlingUnitId}&documentName=${documentName}&mimeType=${mimeType}&contentLength=${contentLength}${
      supplierId ? '&supplierId=' + supplierId : emptyString
    }`,
  getPlasticDocument: (articleId: string, handlingUnitId: string, supplierId?: string) =>
    `${BACK_WEB_ASSORTMENT}/${
      supplierId ? emptyString : 'b2c/'
    }supplierAssortment/plasticDocument?articleId=${articleId}&handlingUnitId=${handlingUnitId}${
      supplierId ? '&supplierId=' + supplierId : emptyString
    }`,
  postPlasticDocument: `${BACK_WEB_ASSORTMENT}/supplierAssortment/plasticDocument`,
  deleteSupplierDocument: (variableArticleId: string, documentId: string) =>
    `${BACK_WEB_ARTICLES}/variableArticles/${variableArticleId}/supplierDocument?documentId=${documentId}`,
  sectionMethod: (sectionId: string) => `${BACK_WEB_ARTICLES}/method/${sectionId}/section`,
  getSectionListMethod: `${BACK_WEB_ARTICLES}/method/sectionList`,
  createSectionMethod: `${BACK_WEB_ARTICLES}/method/createSection`,
  changeOrderSectionMethod: `${BACK_WEB_ARTICLES}/method/changeOrder`,
  getServiceTypes: `${BACK_WEB_ARTICLES}/common/serviceType`,
  sendEmail: (emailTypeId: number) => `${BACK_WEB_ARTICLES}/common/sendEmail?emailTypeId=${emailTypeId}`,
  postArticleModels: `${BACK_WEB_ARTICLES}/articles/models`
};

export const ENDPOINTS_B2C = {
  getSupplierName: `${BACK_WEB_ASSORTMENT_EXT}/supplier/supplierName`,
  productsList: `${BACK_WEB_ARTICLES_EXT}/variableArticles/list`,
  statuses: (statusType: number) => `${BACK_WEB_ARTICLES_EXT}/common/status?statusType=${statusType}`,
  variableArticle: (variableArticle: string) => `${BACK_WEB_ARTICLES_EXT}/variableArticles/${variableArticle}`,
  needs: `${BACK_WEB_ARTICLES_EXT}/needs/`,
  articleTypes: `${BACK_WEB_ARTICLES_EXT}/common/articleType`,
  timeUnits: `${BACK_WEB_ARTICLES_EXT}/common/timeUnits`,
  genericNameClassifications: `${BACK_WEB_ARTICLES_EXT}/common/genericNameClassifications`,
  brands: `${BACK_WEB_ARTICLES_EXT}/common/brands`,
  models: (brandId: number) => `${BACK_WEB_ARTICLES_EXT}/common/brands/${brandId}/models`,
  supplierInfo: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES_EXT}/variableArticles/${variableArticleId}/supplierInfo`,
  getSupplierDocumentList: (articleId: string) =>
    `${BACK_WEB_ARTICLES_EXT}/variableArticles/${articleId}/supplierDocument/list`,
  getUploadSupplierDocumentURL: (
    documentTypeId: number,
    documentName: string,
    mimeType: string,
    contentLength: number,
    variableArticleId: string
  ) =>
    `${BACK_WEB_ARTICLES_EXT}/variableArticles/${variableArticleId}/supplierDocument/upload?documentTypeId=${documentTypeId}&documentName=${documentName}&mimeType=${mimeType}&contentLength=${contentLength}`,
  getSupplierDocument: (documentId: string | undefined, variableArticleId: string) =>
    `${BACK_WEB_ARTICLES_EXT}/b2c/variableArticles/${variableArticleId}/supplierDocument${
      documentId ? '?documentId=' + documentId : emptyString
    }`,
  postSupplierDocument: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES_EXT}/variableArticles/${variableArticleId}/supplierDocument/upload`,
  articles: (variableArticle: string) => `${BACK_WEB_ARTICLES_EXT}/variableArticles/${variableArticle}/articles`,
  listArticleFormats: (variableArticleId: string) =>
    `${BACK_WEB_ASSORTMENT_EXT}/b2c/variableArticles/${variableArticleId}/suppliers/articleFormats`,
  supplierNegotiations: (variableArticleId: string) =>
    `${BACK_WEB_ASSORTMENT_EXT}/variableArticles/${variableArticleId}/supplierNegotiations`,
  supplierNegotiationsInfo: (variableArticleId: string) =>
    `${BACK_WEB_ASSORTMENT_EXT}/b2c/variableArticles/${variableArticleId}/suppliers/negotiations`,
  supplierNegotiationFormats: (variableArticleId: string, negotiationId: string) =>
    `${BACK_WEB_ASSORTMENT_EXT}/b2c/variableArticles/${variableArticleId}/suppliers/negotiations/${negotiationId}/articleFormats`,
  listArticleBySupplierAssortment: (variableArticleId: string) =>
    `${BACK_WEB_ASSORTMENT_EXT}/b2c/variableArticles/${variableArticleId}/suppliers/articleFormats`,
  getSupplierAssortment: (articleId?: string, handlingUnitId?: string) =>
    `${BACK_WEB_ASSORTMENT_EXT}/supplierAssortment?articleId=${articleId ?? emptyString}&handlingUnitId=${
      handlingUnitId ?? emptyString
    }`,
  amountObservations: (observationType: number) =>
    `${BACK_WEB_ASSORTMENT_EXT}/supplierAssortment/amountObservations?observationType=${observationType}`,
  updateSupplierAssortmentAsSupplier: (articleId?: string, handlingUnitId?: string) =>
    `${BACK_WEB_ASSORTMENT_EXT}/supplierAssortment/supplierInfo?articleId=${articleId ?? emptyString}&handlingUnitId=${
      handlingUnitId ?? emptyString
    }`,
  getUploadPlasticDocumentURL: (
    articleId: string,
    handlingUnitId: string,
    documentName: string,
    mimeType: string,
    contentLength: number
  ) =>
    `${BACK_WEB_ASSORTMENT_EXT}/b2c/supplierAssortment/plasticDocument/upload?articleId=${articleId}&handlingUnitId=${handlingUnitId}&documentName=${documentName}&mimeType=${mimeType}&contentLength=${contentLength}`,
  getPlasticDocument: (articleId: string, handlingUnitId: string) =>
    `${BACK_WEB_ASSORTMENT_EXT}/b2c/supplierAssortment/plasticDocument?articleId=${articleId}&handlingUnitId=${handlingUnitId}`,
  postPlasticDocument: `${BACK_WEB_ASSORTMENT_EXT}/b2c/supplierAssortment/plasticDocument`,
  listFormats: (articleId: string) => `${BACK_WEB_ARTICLES_EXT}/b2c/articles/${articleId}/formats`,
  handlingUnits: `${BACK_WEB_ARTICLES_EXT}/common/handlingUnits`,
  unitOfMeasure: `${BACK_WEB_ARTICLES_EXT}/common/unitsOfMeasure`,
  format: (articleId: string, handlingUnitId: string) =>
    `${BACK_WEB_ARTICLES_EXT}/b2c/articles/${articleId}/formats/${handlingUnitId}`,
  listArticleFormatsGrouped: (variableArticleId: string) =>
    `${BACK_WEB_ARTICLES_EXT}/variableArticles/${variableArticleId}/articleFormats`,
  certificationDatePriceTypes: `${BACK_WEB_ASSORTMENT_EXT}/supplierAssortment/certificationDatePriceTypes`,
  deleteSupplierDocument: (variableArticleId: string, documentId: string) =>
    `${BACK_WEB_ARTICLES_EXT}/b2c/variableArticles/${variableArticleId}/supplierDocument?documentId=${documentId}`
};
