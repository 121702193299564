import { Injectable } from '@angular/core';

import { emptyString } from 'app/constants/global.constants';
import { CommonService } from 'app/repository/services/common/common.service';

/**
 * CommonApiService
 */
@Injectable({
  providedIn: 'root'
})
export class CommonApiService {
  /**
   * Class constructor
   *
   * @param {CommonService} commonService commonService
   */
  constructor(private commonService: CommonService) {}

  getPurchasingManagers(hierarchyNodeId: string) {
    return this.commonService.getPurchasingManagers(hierarchyNodeId);
  }

  getBusinessCenterSubtypes() {
    return this.commonService.getBusinessCenterSubtypes();
  }

  getArticleTypes(isSupplierExt: boolean, _hierarchyNodeId?: string) {
    return this.commonService.getArticleTypes(isSupplierExt);
  }

  getServiceTypes() {
    return this.commonService.getServiceTypes();
  }

  getCostCenterTypes() {
    return this.commonService.getCostCenterTypes();
  }

  getBudgetImplementationTypes(needId?: string) {
    return this.commonService.getBudgetImplementationTypes(needId);
  }

  getBudgetImplementationTypesNeed(hierarchyNodeId: string) {
    return this.commonService.getBudgetImplementationTypesNeed(hierarchyNodeId);
  }

  getStatuses(statusType: number, isSupplierExt: boolean) {
    return this.commonService.getStatuses(statusType, isSupplierExt);
  }

  getCostCenters() {
    return this.commonService.getCostCenters();
  }

  getSuppliers(search?: string, pageNumber?: number, pageSize?: number) {
    return this.commonService.getSuppliers(search ?? emptyString, pageNumber, pageSize);
  }

  /**
   * codeGenerator
   *
   * @param {string} typeId :1 = needs, :2 articles
   * @param {string} codeAmount :1
   * @returns {object} res res
   */
  codeGenerator(typeId: string, codeAmount: number) {
    return this.commonService.codeGenerator(typeId, codeAmount);
  }

  getBrands(isSupplierExt: boolean) {
    return this.commonService.getBrands(isSupplierExt);
  }

  createBrand(brandName: string) {
    return this.commonService.createBrand(brandName);
  }

  getModels(brandId: number, isSupplierExt: boolean) {
    return this.commonService.getModels(brandId, isSupplierExt);
  }

  createModel(brandId: number, modelName: string) {
    return this.commonService.createModel(brandId, modelName);
  }

  createWhatIsIt(newWhatIsIt: string, hierarchyNodeId: string) {
    return this.commonService.createWhatIsIt(newWhatIsIt, hierarchyNodeId);
  }

  getCurrency() {
    return this.commonService.getCurrency();
  }

  getCurrencySupplier(supplierId: string) {
    return this.commonService.getCurrencySupplier(supplierId);
  }

  getTimeUnits(isSupplierExt: boolean) {
    return this.commonService.getTimeUnits(isSupplierExt);
  }

  getNodeType(hierarchyNodeId: string) {
    return this.commonService.getNodeType(hierarchyNodeId);
  }

  getImageProfile() {
    return this.commonService.getImageProfile();
  }

  getChargeOfMaintenance() {
    return this.commonService.getChargeOfMaintenance();
  }

  getGenericNameClassifications(hierarchyNodeId: string, variableArticleId: string, isSupplierExt: boolean) {
    return this.commonService.getGenericNameClassifications(hierarchyNodeId, variableArticleId, isSupplierExt);
  }

  /**
   * sendEmail
   *
   * @param {number} emailTypeId :1 = whatIsIt
   * @param {object} body body
   * @returns {object} res res
   */
  sendEmail(emailTypeId: number, body: object) {
    return this.commonService.sendEmail(emailTypeId, body);
  }
}
