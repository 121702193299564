/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { GenericExtender } from '@interfaces/generic-extender.interface';
import { emptyString } from 'app/constants/global.constants';
import { isArrayProperty, isDateProperty, isObjectProperty } from 'app/functions/make-generic-extender.function';
import { IFileList, IGenericName, INames } from 'app/repository/models/product.models';

export type MappedPurchasingDataType = ReturnType<typeof purchasingDataPostMapper>;

/**
 *
 * @param {Partial<PurchasingDataType>} value value
 * @returns {MappedPurchasingDataType} mapped mapped
 */
export function purchasingDataPostMapper(value: Partial<PurchasingDataType>) {
  return {
    genericNameClassificationId: value.genericNameClassificationId?.id,
    descriptions: [
      {
        localeLanguage: 'es-ES',
        name: value.descriptionES
      },
      {
        localeLanguage: 'pt-PT',
        name: value.descriptionPT
      }
    ],
    brand: value.brand,
    model: value.model,
    isLpcExclusive: value.isLpcExclusive,
    isKit: value.isKit,
    isDangerous: value.isDangerous,
    taricCode: value.taricCode,
    plannedUsefulLife: value.plannedUsefulLife,
    plannedUsefulLifeType: value.plannedUsefulLifeType
  };
}

export type PurchasingDataType = {
  genericNameClassificationId: GenericExtender<IGenericName> | null;
  descriptionES: string | null;
  descriptionPT: string | null;
  brand: string | null;
  model: string | null;
  isLpcExclusive: boolean | null;
  isDangerous: boolean | null;
  isKit: boolean | null;
  life: string | null;
  taricCode: string | null;
  plannedUsefulLife: number | null;
  plannedUsefulLifeType: INames[] | null;
};

/**
 * DataService
 */
@Injectable({
  providedIn: 'root'
})
export class DataService {
  formGroup!: ReturnType<typeof this.buildForm>;

  private bSubject!: BehaviorSubject<
    | {
        mappedValueForm: MappedPurchasingDataType;
        validValueForm: boolean;
      }
    | undefined
  >;

  get bSubjectGetter() {
    return this.bSubject.getValue();
  }

  brands!: { id: string; name: string }[];

  models!: { id: string; name: string }[];

  genericNameClassifications!: GenericExtender<{ id: string; names: INames[] }>[];

  /**
   * Class constructor
   *
   * @param {FormBuilder} formBuilder formBuilder
   */
  constructor(private formBuilder: FormBuilder) {
    this.initForm();
  }

  nextMappedValueForm(value: Partial<PurchasingDataType>, valid: boolean) {
    this.bSubject.next({
      mappedValueForm: purchasingDataPostMapper(value),
      validValueForm: valid
    });
  }

  initForm() {
    this.brands = [];
    this.models = [];
    this.formGroup = this.buildForm();
    this.bSubject = new BehaviorSubject<
      | {
          mappedValueForm: MappedPurchasingDataType;
          validValueForm: boolean;
        }
      | undefined
    >(undefined);
    this.nextMappedValueForm(this.formGroup.getRawValue(), this.formGroup.valid);
    return this.formGroup;
  }

  buildForm() {
    return this.formBuilder.group({
      genericNameClassificationId: new FormControl<GenericExtender<IGenericName> | null>(null, [
        Validators.required,
        Validators.maxLength(4000),
        checkHasChangedFormControlValidator
      ]),
      descriptionES: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(4000),
        checkHasChangedFormControlValidator
      ]),
      descriptionPT: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(4000),
        checkHasChangedFormControlValidator
      ]),
      brand: new FormControl<string | null>(null, [checkHasChangedFormControlValidator]),
      model: new FormControl<string | null>(null, [checkHasChangedFormControlValidator]),
      isLpcExclusive: new FormControl<boolean | null>(false, [checkHasChangedFormControlValidator]),
      isDangerous: new FormControl<boolean | null>(false, [checkHasChangedFormControlValidator]),
      isKit: new FormControl<boolean | null>(false, [checkHasChangedFormControlValidator]),
      technicalDatasheet: new FormControl<string[] | null>([], []),
      life: new FormControl<string | null>(null, []),
      taricCode: new FormControl<string | null>(null, []),
      plannedUsefulLife: new FormControl<number | null>(null, []),
      plannedUsefulLifeType: new FormControl<INames[] | null>(null, []),
      //fileNames
      explodedViewFileList: new FormControl<IFileList | null>(null, []),
      technicalDatasheetFileList: new FormControl<IFileList | null>(null, []),
      hasDiferentModels: new FormControl<boolean | null>(null, [])
    });
  }

  buildDialogForm() {
    return this.formBuilder.group({
      newBrand: new FormGroup({
        brandName: new FormControl<string | null>(null, [Validators.required])
      }),
      newModel: new FormGroup({
        selectedBrand: new FormControl<number | null>(null, [Validators.required]),
        modelName: new FormControl<string | null>({ value: null, disabled: true }, [Validators.required])
      })
    });
  }

  buildDialogWhatIsItForm() {
    return this.formBuilder.group({
      whatIsIt: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(4000),
        checkHasChangedFormControlValidator
      ])
    });
  }
}

/**
 *
 * @param {AbstractControl} _control controls abstract
 * @returns {null} nothing
 */
export function checkHasChangedFormControlValidator(_control: AbstractControl) {
  return null;
}

/**
 *
 * @param {FormGroup} fg form group
 * @param {AbstractControl} og abstract controls
 * @param {boolean} markAsInvalid boolean mark as invalid
 */
export function addHasChangedFormGroupValidator(fg: FormGroup, og?: AbstractControl, markAsInvalid: boolean = true) {
  fg.addValidators([hasChangedFormGroupValidator(fg, og, markAsInvalid)]);
  Object.defineProperty(fg, 'projectOldValue', {
    value: og,
    configurable: true
  });
}

/**
 *
 * @param {object} list1 list 1
 * @param {object} list2 list 2
 * @returns {object} list
 */
function reorderList<T>(list1: T[], list2: T[]): T[] {
  // Create a map to store the position of each element in list2
  const indexMap = new Map<T, number>();
  list2.forEach((item, index) => {
    indexMap.set(item, index);
  });

  // Sort list1 based on the index of matching elements in list2
  return list1.slice().sort((a, b) => {
    const indexA = indexMap.has(a) ? indexMap.get(a)! : Infinity;
    const indexB = indexMap.has(b) ? indexMap.get(b)! : Infinity;
    return indexA - indexB;
  });
}

/**
 *
 * @param {unknown} newValue new value
 * @param {unknown} oldValue old value
 * @returns {boolean} boolean
 */
function isDataComplex(newValue: unknown, oldValue: unknown) {
  let res: {
    b: boolean;
    clazz: string | null;
  } = {
    b: false,
    clazz: null
  };
  const match = [
    {
      b: isObjectProperty(newValue) && isObjectProperty(oldValue),
      clazz: '[object Object]'
    },
    {
      b: isArrayProperty(newValue) && isArrayProperty(oldValue),
      clazz: '[object Array]'
    },
    {
      b: isDateProperty(newValue) && isDateProperty(oldValue),
      clazz: '[object Date]'
    }
  ].find((e) => e.b);
  if (match) {
    res = match;
  }
  return res;
}

/**
 *
 * @param {unknown} newValue new value
 * @param {unknown} oldValue old value
 * @returns {boolean} boolean
 */
function checkHasChangedFormValue(
  newValue: unknown,
  oldValue: unknown,
  extraConfiguration?: {
    [p: string]: unknown;
  }
) {
  const { b, clazz } = isDataComplex(newValue, oldValue);
  if (b) {
    let innerRes = JSON.stringify(newValue) !== JSON.stringify(oldValue);
    if (clazz === '[object Array]' && extraConfiguration && extraConfiguration.multiple) {
      let newValueCloned = structuredClone(newValue as unknown[]).map((e: unknown) => JSON.stringify(e));
      const oldValueCloned = structuredClone(oldValue as unknown[]).map((e: unknown) => JSON.stringify(e));
      newValueCloned = reorderList<string>(newValueCloned, oldValueCloned);
      innerRes = JSON.stringify(newValueCloned) !== JSON.stringify(oldValueCloned);
    }
    return innerRes;
  }
  if (newValue !== oldValue) {
    if (Object.prototype.toString.call(newValue) === Object.prototype.toString.call(oldValue)) {
      return true;
    }
    if (
      ![newValue, oldValue].every((v) =>
        [null, undefined, emptyString, [], {}].some((e) => JSON.stringify(e) === JSON.stringify(v))
      )
    ) {
      return true;
    }
  }

  return false;
}

/**
 *
 * @param {FormGroup} oldFormGroup old form group
 * @param {FormGroup} group group
 * @param {string} key key
 * @returns {boolean} boolean
 */
function hasChangedFunc(oldFormGroup: FormGroup, group: FormGroup, key: string) {
  const f = group.get(key);
  const fc = f as typeof f & { _rawValidators: ValidatorFn[] };
  const res = false;
  let fn = () => {
    let innerRes = false;
    if (fc && fc.hasValidator(checkHasChangedFormControlValidator)) {
      const newValue = fc.value;
      const oldValue = oldFormGroup.get(key)?.value;
      innerRes = checkHasChangedFormValue(newValue, oldValue);
    }
    return innerRes;
  };
  const matchFn = (fc._rawValidators || [])
    .map((e) => e as { name: string; extraConfiguration?: { [p: string]: unknown } })
    .find((e) => {
      const possibleValidatorNames = [
        'checkHasChangedFormControlValidator',
        'bound checkHasChangedFormControlValidator'
      ];
      let ress = possibleValidatorNames.includes(e.name);
      if (e.extraConfiguration?.fnValidatorName) {
        ress = possibleValidatorNames.includes(e.extraConfiguration.fnValidatorName as string);
      }
      return ress;
    });
  if (fc && matchFn) {
    fn = () => {
      const extraConfiguration = (matchFn as unknown as object & { extraConfiguration?: { [p: string]: unknown } })
        .extraConfiguration;
      const newValue = fc.value;
      const oldValue = oldFormGroup.get(key)?.value;
      return checkHasChangedFormValue(newValue, oldValue, extraConfiguration);
    };
  }
  return res || fn();
}

/**
 *
 * @param {FormGroup} fg form group
 * @param {AbstractControl} og abstract controls
 * @param {boolean} markAsInvalid boolean mark as invalid
 * @returns {boolean} hasChangedValidator
 */
export function hasChangedFormGroupValidator(
  fg: FormGroup,
  og?: AbstractControl,
  markAsInvalid: boolean = true
): ValidatorFn {
  return (g: AbstractControl): ValidationErrors | null => {
    if (!og) {
      return { hasChangedValidator: true };
    }
    const oldFormGroup = og as typeof fg;
    const group = g as typeof fg;
    const hasChanged = Object.keys(group.controls).some((key) => hasChangedFunc(oldFormGroup, group, key));
    if (hasChanged) {
      Object.defineProperty(group, 'hasChanged', {
        value: true,
        configurable: true
      });
      return null;
    }
    Object.defineProperty(group, 'hasChanged', {
      value: false,
      configurable: true
    });
    if (markAsInvalid) {
      return { hasChangedValidator: true };
    }
    return null;
  };
}
