import { Pipe, PipeTransform } from '@angular/core';

import { emptyString } from 'app/constants/global.constants';

@Pipe({
  name: 'firstLetterCapitalize'
})
export class FirstLetterCapitalizePipe implements PipeTransform {
  transform(value: string, decapitalizeRest: boolean = true, compoundWord: boolean = true) {
    if (!value || value.length === 0) {
      return emptyString;
    }
    if (compoundWord) {
      return value
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + (decapitalizeRest ? word.slice(1).toLowerCase() : word.slice(1)))
        .join(' ');
    }
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
