import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ENDPOINTS } from 'app/config/endpoints';
import {
  INewSectionBody,
  IOrderSectionsBody,
  ISectionBody,
  ISectionDetailResponse,
  ISectionsListResponse
} from 'app/repository/models/method.models';

@Injectable({
  providedIn: 'root'
})
export class MethodService {
  constructor(private httpClient: HttpClient) {}

  getInfoSection(sectionId: string) {
    const endpoint = `${ENDPOINTS.sectionMethod(sectionId)}`;
    return this.httpClient.get<ISectionDetailResponse>(endpoint);
  }

  deleteSection(sectionId: string) {
    const endpoint = `${ENDPOINTS.sectionMethod(sectionId)}`;
    return this.httpClient.delete(endpoint);
  }

  getSectionList(body: ISectionBody) {
    const endpoint = `${ENDPOINTS.getSectionListMethod}`;
    return this.httpClient.post<ISectionsListResponse>(endpoint, body);
  }

  createSection(body: INewSectionBody) {
    const endpoint = `${ENDPOINTS.createSectionMethod}`;
    return this.httpClient.post<object>(endpoint, body);
  }

  changeOrderSections(body: IOrderSectionsBody) {
    const endpoint = `${ENDPOINTS.changeOrderSectionMethod}`;
    return this.httpClient.post<object>(endpoint, body);
  }
}
