import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  INewSectionBody,
  IOrderSectionsBody,
  ISectionBody,
  ISectionDetailResponse,
  ISectionsListResponse
} from 'app/repository/models/method.models';
import { MethodService } from 'app/repository/services/method/method.service';

@Injectable({
  providedIn: 'root'
})
export class MethodApiService {
  constructor(private methodService: MethodService) {}

  getInfoSection(sectionId: string): Observable<ISectionDetailResponse> {
    return this.methodService.getInfoSection(sectionId);
  }

  deleteSection(sectionId: string) {
    return this.methodService.deleteSection(sectionId);
  }

  getSectionList(body: ISectionBody): Observable<ISectionsListResponse> {
    return this.methodService.getSectionList(body);
  }

  createSection(body: INewSectionBody) {
    return this.methodService.createSection(body);
  }

  changeOrderSections(body: IOrderSectionsBody) {
    return this.methodService.changeOrderSections(body);
  }
}
